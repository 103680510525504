<template>
	<div class="error">
		<StsCard>
			<h1>{{ status.code }}</h1>
			<h2>{{ status.text }}</h2>
		</StsCard>

		<section class="container">
			<h3 v-if="!status.message">Oops!</h3>
			<p v-if="!status.message">There was an error processing your request.</p>
			<p v-else>{{ status.message }}</p>

			<router-link :to="{ name: 'Authentication' }" class="button-link">Return home</router-link>
		</section>
	</div>
</template>





<script>
import { mapGetters, mapState } from 'vuex';
import { StsCard } from '@/components/SwitchThink';

export default {
	name: 'Error',
	components: { StsCard },
	computed: {
		...mapGetters('application', ['productType']),
		...mapState('error', {
			statusCode: state => state.statusType,
			statusText: state => state.statusText,
			message: state => state.message
		}),
		status() {
			const message = 'There was an error processing your request.';
			const statusTypes = {
				400: { code: 400, text: 'Bad Request', message },
				401: {
					code: 401,
					text: 'Unauthorized',
					message: `Sorry, you don't have permission to view the page requested.`
				},
				404: {
					code: 404,
					text: 'Page Not Found.',
					message: `Sorry, the page you've requested no longer exists or has been moved. Please return home and try again.`
				},
				500: { code: 500, text: 'Error', message: `There was an error with your request.` }
			};
			const storedError = { code: this.statusCode, text: this.statusText, message: this.message };
			const genericError = {
				code: this.$route.params.statusType,
				text: 'Well this is embarassing...',
				message: ''
			};

			if (this.$route.params.statusType === this.statusCode) {
				return storedError;
			}
			return statusTypes[this.$route.params.statusType] || genericError;
		}
	}
};
</script>





<style lang="scss" scoped>
.error {
	margin-top: 6rem;
	text-align: center;

	h1 {
		font-size: 4rem;
		line-height: 4rem;
		font-weight: 400;
		color: var(--primary);
		margin-top: 0;
		margin-bottom: 0.75rem;
	}

	h2 {
		font-size: 1.25rem;
		font-weight: 300;
	}

	h3 {
		font-size: 2rem;
		font-weight: 300;
		color: var(--primary);
		margin-bottom: 0.75rem;
	}

	.container {
		margin-top: 1.5rem;
		text-align: center;

		p {
			font-weight: 300;
			font-size: 1.125rem;
			line-height: 1.25rem;
			color: var(--bodyCopy);

			&:not(:last-child) {
				margin-bottom: 1.5rem;
			}
		}

		.phone-link {
			text-decoration: underline;
			color: var(--secondary);
			font-weight: 300;
			font-size: 1.125rem;
			line-height: 1.25rem;
			transition: opacity 0.5s;

			&:hover {
				opacity: 0.4;
			}
		}

		.button-link {
			display: inline-block;
			padding: 15px 45px;
			text-align: center;
			vertical-align: middle;
			font-size: 1rem;
			font-weight: 600;
			transition: all 0.3s linear;
			text-decoration: none;
			background-color: var(--white);
			border: 2px solid var(--primary);
			color: var(--primary);

			&:hover {
				background-color: var(--primary);
				border: 2px solid var(--primary);
				color: var(--white);
			}
		}
	}
}
</style>